import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HeadersInterceptor } from 'src/interceptors/headers.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { SharedModule } from './modules/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from "../environments/environments";
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { initializeApp } from "firebase/app";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DatePipe } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    BlankComponent,
    FullComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    NgbAccordionModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


