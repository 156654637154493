<div class="navbar-2 p-15" >
    <img class="tkf-logo cursor-pointer" src="../../../../assets/images/logo.png" alt="" routerLink="/">
    <span class="light-logo font-14 font-600 text-white">Texas Kidney Foundation</span>
</div>

<div class="container text-center main-heading position-relative">
    <div class="text-start">
        <i class="fa fa-solid fa-arrow-left cursor me-2 font-20 back-button" routerLink="/auth/login"></i>
    </div>
    <h1 class="privacy-policy-heading mb-0">Privacy Policy</h1>
    <p class="mb-3 font-500">Last Updated: 04 Oct 2024</p>
</div>

<div class="container">
    <div class="card shadow mb-4 privacy-policy-area">
        <div class="card-body">
            <p>
                At six21two21, we are committed to protecting your privacy and ensuring the security of your personal information. 
                This policy outlines how we collect, use, share, and protect your data through the mobile app used by the Texas Kidney Foundation (TKF) for collecting health data at community health fairs. Our privacy practices are designed to comply with applicable privacy laws, including the Health Insurance Portability and Accountability Act (HIPAA).
            </p>

            <div>
                <strong>1. Participant Consent for Data Usage</strong><br>
                    <p>Participants using the six21two21 platform provide consent for their health data to be collected, stored, and used according to this policy. By participating in TKF’s community health fairs and using the app, you consent to the collection and use of your data as outlined in this privacy policy.</p>
                    <ul>
                        <li>
                            <strong>Data Collection:</strong> 
                            <p class="mb-0">Data will be collected from health screenings, demographic information, and self-reported information.</p> 
                        </li>
                        <li>
                            <strong>Right to Revoke Consent:</strong> 
                            <p>You may revoke consent at any time by contacting six21two21 or TKF. Upon revoking consent, you may request to have your data deleted (see Section 4 for details).</p>
                        </li>
                    </ul>
            </div>
            <div class="container mt-4">
                <strong>2. How We Use Your Information</strong><br>
                <p>We use your information for a variety of purposes, including health research, service improvements, and legal compliance. Below are the key ways we may use your data:</p>
                
                <ul class="two-information">
                    <li>
                        <strong>a) Advancing Health and Medical Science:</strong> 
                        Your de-identified data may be shared with research institutions, healthcare providers, or pharmaceutical companies to advance public health research. This data is aggregated and anonymized to ensure your personal information is protected. You must opt-in to any specific research study before your de-identified data is shared.
                    </li>
                    <li>
                        <strong>b) Research Recruitment:</strong> 
                        Based on your de-identified health data, you may receive invitations to participate in relevant research studies or clinical trials. These invitations will come through the app, and you have the choice to opt-in or out of receiving such invitations at any time.
                    </li>
                    <li>
                        <strong>c) Community Resources and Study Participation:</strong> 
                        We may notify you about new health initiatives, resources available in your community, or study opportunities relevant to your health profile. These notifications will be based on your anonymized data and can be managed within the app’s settings.
                    </li>
                    <li>
                        <strong>d) Improving Services:</strong> 
                        We use collected data to improve the functionality of the platform, enhance user experiences, and ensure the security of the system. Data may also be used to detect and fix technical issues or to develop new features that better serve participant needs.
                    </li>
                    <li>
                        <strong>e) Legal Compliance:</strong> 
                        We may disclose your data when required by law or in response to valid legal processes, such as subpoenas, court orders, or government regulations. When possible, we will notify you before disclosing your information, and we will ensure that any identifying information remains protected unless required by law.
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>3. No Participant Ownership in six21two21</strong><br>
                   <p>Unlike LunaDNA’s model, participants using six21two21 do not receive ownership shares in the company in exchange for their data. Your data remains under your control and you have full rights to access, modify, or delete it at any time.</p>
                    <ul>
                        <li>
                            <strong>No Ownership or Shares:</strong> 
                            <p class="mb-0">While your data contributes to research, six21two21 does not offer equity or ownership in exchange for data contributions.</p> 
                        </li>
                        <li>
                            <strong>Data Rights:</strong> 
                            <p> You maintain the right to your data, and the control over who can access it for research purposes.</p>
                        </li>
                    </ul>
            </div>

            <div class="container mt-4">
                <strong>4. Data Rights of Each Individual</strong><br>
                <p>six21two21 is committed to providing you with full control over your data. You have the following rights concerning your personal and health data:</p>
                
                <ul class="two-information">
                    <li>
                        <strong>a) Right to Be Informed:</strong> 
                        You have the right to be fully informed about how your data is collected, used, and shared. This privacy policy provides details, and additional information is available via the app's dashboard.
                    </li>
                    <li>
                        <strong>b) Right of Access:</strong> 
                        You can request to access your health data through the six21two21 app. You will be able to view, download, or print your health data, including any information collected from TKF health screenings.
                    </li>
                    <li>
                        <strong>c) Right to Rectification:</strong> 
                        If any of your personal or health information is incorrect or outdated, you have the right to correct it. You can submit updates to your data through the app or by contacting TKF or your healthcare provider to correct the data at the source.
                    </li>
                    <li>
                        <strong>d) Right to Object to Processing:</strong> 
                        You may revoke your consent to data processing at any time, either for specific uses (such as research) or entirely. Upon revocation, we will delete your data or stop processing it in line with your request.
                    </li>
                    <li>
                        <strong>e) Right to Restrict Processing:</strong> 
                        You have the right to limit how your data is used, such as opting out of certain research projects or limiting data sharing.
                    </li>
                    <li>
                        <strong>f) Right to Data Portability:</strong> 
                        You can request a downloadable version of your data in a standard format. This allows you to transfer your health data to other services or platforms if you wish.
                    </li>
                    <li>
                        <strong>g) Right to Be Forgotten:</strong> 
                        You may request to have your personal data permanently deleted from six21two21’s database. Once requested, your data will be purged according to our data retention policies.
                    </li>
                    <li>
                        <strong>h) No Automated Decision-Making or Profiling:</strong> 
                        six21two21 does not use your data for automated decision-making or profiling without your explicit consent.
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>5. Data Deletion (Data Purge)</strong>
                <p>If you wish to permanently delete your data, you can request a Data Purge. This will remove your information from six21two21’s database and any research collaborations.</p>
                <ul>
                    <li>
                        <strong>How to Request:</strong> 
                        <p class="mb-0">You may request data deletion by contacting us at [Insert Contact Information]. We will confirm your request and ensure the secure deletion of your personal information.</p> 
                    </li>
                    <li>
                        <strong>Timeframe:</strong> 
                        <p>Once the request is made, your data will be purged within [Insert Timeframe], and you will receive a confirmation once the process is complete.</p>
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>6. Data Archival</strong><br>
                <p>If you wish to stop actively using the platform but retain your data in a secure, inactive state, you can request a Data Archive. This allows six21two21 to store your data without using it for active purposes, while maintaining access for potential future use if you reactivate your account.</p>
                <ul>
                    <li>
                        <strong>How to Archive Data:</strong> 
                        <p class="mb-0">You may request a data archive at any time. This option allows for data storage without any active usage or sharing until you authorize further use.</p> 
                    </li>
                    <li>
                        <strong>Reactivation:</strong> 
                        <p>You may request to reactivate your archived data at any time.</p>
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>7. Third-Party Tools</strong><br>
                <p>six21two21 uses third-party service providers to help operate and enhance our platform, ensuring your data is protected through appropriate safeguards and privacy agreements with these providers. These third-party services include:</p>
                <ul>
                    <li>
                        <strong>Data Hosting:</strong> 
                        <p class="mb-0">We utilize Google Cloud to securely store and process data. Google Cloud complies with industry-standard data protection regulations, ensuring your data is encrypted and safeguarded.</p>
                    </li>
                    <li>
                        <strong>Electronic Health Records (EHR):</strong> 
                        <p class="mb-0">Through integrations with secure APIs, six21two21 allows you to import and manage health data from EHR systems. All third-party EHR data integrations comply with HIPAA standards to protect your health information.</p>
                    </li>
                    <li>
                        <strong>Communications:</strong> 
                        <p class="mb-0">We use Mailchimp to manage communications such as notifications and updates. Mailchimp is a GDPR-compliant service that ensures your contact information is handled securely.</p>
                    </li>
                    <li>
                        <strong>Support Services:</strong> 
                        <p class="mb-0">six21two21 uses Freshworks for customer support and help desk functionality, ensuring your requests and data are managed securely. Freshworks complies with major privacy regulations including GDPR and CCPA.</p>
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>8. Security & Privacy Measures</strong><br>
                <p>We take the security and privacy of your data seriously and implement multiple layers of protection to keep your information safe. Our security practices include:</p>
                <ul>
                    <li>
                        <strong>Data Encryption:</strong> 
                        <p class="mb-0">All data transmitted to and from our platform is encrypted using SSL (Secure Socket Layer) technology, and data stored within our systems is encrypted at rest. This ensures your information remains secure at all times.</p>
                    </li>
                    <li>
                        <strong>De-identification for Research:</strong> 
                        <p class="mb-0">When sharing data for research purposes, six21two21 ensures that all identifying information is removed, and your data is anonymized to protect your identity. This process includes aggregating data with that of other participants to further reduce the risk of re-identification.</p>
                    </li>
                    <li>
                        <strong>HIPAA Compliance:</strong> 
                        <p class="mb-0">As a third-party platform providing services to the Texas Kidney Foundation, we comply with HIPAA regulations to ensure the confidentiality and security of your health information. Our security policies are regularly reviewed and updated to maintain compliance with industry standards.</p>
                    </li>
                    <li>
                        <strong>Regular Audits:</strong> 
                        <p class="mb-0">six21two21 conducts regular security audits and assessments to identify and mitigate risks, ensuring that our data protection practices stay up to date with the latest security threats and standards.</p>
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>9. De-identified Data for Research Collaborations</strong><br>
                <p>To support medical research and advance public health, six21two21 may share de-identified and aggregated data with research institutions and healthcare providers. This data helps researchers understand health trends, develop new treatments, and improve care models.</p>
                <ul>
                    <li>
                        <strong>De-identification Process:</strong> 
                        <p class="mb-0">Before sharing data, all personally identifiable information (e.g., names, addresses) is removed to ensure your privacy. We follow the industry best practices for de-identification to protect your identity.</p>
                    </li>
                    <li>
                        <strong>Research Collaborations:</strong> 
                        <p class="mb-0">Your de-identified data may be used in studies that contribute to new treatments, clinical trials, or health insights. However, no personal data that could identify you will be shared without your consent.</p>
                    </li>
                </ul>
            </div>

            <div class="container mt-4">
                <strong>10. Changes to This Privacy Policy</strong><br>
                <p>As six21two21 evolves and new regulations or platform features emerge, we may need to update this Privacy Policy. We will notify you of any changes by posting the new Privacy Policy on our platform and updating the "effective date" at the top of this document. We encourage you to review this Privacy Policy periodically for any changes.</p>
            </div>
            
        </div>
    </div>

    <div class="text-center d-flex align-items-center justify-content-center gap-2">
     <img src="../../../../assets/images/tkf-logo.png" alt="" height="28" width="28">
     <span class=" font-14 font-400">Powered by SIX21TWO21</span>
    </div>

</div>